import { SOURCE } from "../utils"

export const getApplications = async (token, params) => {
    const query = new URLSearchParams()
    for (const key in params) {
        if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
            if (Array.isArray(params[key])){
                params[key].forEach(el => {
                    query.append(key, el)
                });
            }
            else query.append(key, params[key]);
        }
      }
      

    const response = await fetch(SOURCE.api_url + 'applications?' + query.toString(),
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
        }
    )
    return response
}

export const bulkCreateApplications = async (token, data) => {
    const response = await fetch(SOURCE.api_url + 'applications/bulk',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}

export const createApplication = async (token, data) => {
    const response = await fetch(SOURCE.api_url + 'applications',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}

export const patchApplication = async (token, data, id) => {
    const response = await fetch(SOURCE.api_url + 'applications/' + id,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}


export const safePatchApplication = async (token, data, id) => {
    const response = await fetch(SOURCE.api_url + `applications/${id}/safe`,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}

export const deleteApplication = async (token, id) => {
    const response = await fetch(SOURCE.api_url + 'applications/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
        }
    )
    return response
}

export const importData = async (token, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await fetch(SOURCE.api_url + 'dataManagement/import',
        {
            method: 'POST',
            headers: {
                "Authorization": token
            },
            body: formData
        }
    )
    return response
}

export const exportData = async (token) => {
    const response = await fetch(SOURCE.api_url + 'dataManagement/exportAll',
        {
            method: 'GET',
            headers: {
                "Authorization": token
            }
        }
    )
    return response
}

export const downloadtAppointment = async (token, urn) => {
    const response = await fetch(SOURCE.api_url + 'vfs-appointments/download/' + urn,
        {
            method: 'GET',
            headers: {
                "Authorization": token
            },
        }
    )
    return response
}