const moment = require("moment");

export const CREATE_APPLICATION_STEPS = {
  PASSPORT_OR_FILE: 'PASSPORT_OR_FILE',
  FILE_PREVIEW: 'FILE_PREVIEW',
  APPLICATION_SINGLE: 'APPLICATION_SINGLE',
  INTERVALS: 'INTERVALS',
  FINISH: 'FINISH',

}

export const CITIZENSHIPS = {
  RUS: 'RUS',
  ARM: 'ARM',
  BLR: 'BLR',
  KAZ: 'KAZ',
  KGZ: 'KGZ',
  MDA: 'MDA',
  TJK: 'TJK',
  UZB: 'UZB'
}

export const GENDERS = {
  MALE: 'Мужской',
  FEMALE: 'Женский',
  OTHERS: 'Другой',
}

export const VISA_TYPES = {
  short_term: {
    VS_PT_1: 'PRIME TIME (55 euros) Short Stay All kind of other short stay visas',
    VS_1_5: 'Другие краткосрочные визы',
  },
  long_term: {
    VS_2_1: 'Долгосрочная виза для обучения в высшем учебном заведений',
    VS_2_2: 'Carte PRO MAE',
    VS_2_3: 'Salarié OFII проект "СОЮЗ"',
    VS_2_4: 'ученые, приглашенные французскими научно исследовательскими институтами',
    VS_2_5: 'Lecteur et assistant de langue',
    VS_2_6: 'Долгосрочная виза Passeport Talent + сопровождающие члены семьи',
    VS_2_13: 'Долгосрочная виза "Бенифициары положительного решения OFII о воссоединении семьи"',
    VS_2_7: 'Другие долгосрочные визы',
  }
}

export const PRIORITY_LEVELS = {
  2: 'Обычный',
  1: 'Высокий'
}

export const AVAILABLE_CITIES = {
  MOSCOW: 'Москва',
  SAINT_PETERSBURG: 'Санкт-Петербург',
  KALININGRAD: 'Калининград',
  YEKATERINBURG: 'Екатеринбург',
  ROSTOV_ON_DON: 'Ростов-на-Дону',
  KRASNODAR: 'Краснодар',
  // IRKUTSK: 'Иркутск',
  // KAZAN: 'Казань',
  // NIZHNIY_NOVGOROD: 'Нижний новгород',
  // NOVOSIBIRSK: 'Новосибирск',
  // PERM: 'Пермь',
  // SAMARA: 'Самара',
  // VLADIVOSTOK: 'Владивосток',
  // KHABAROVSK: 'Хабаровск',
  // KRASNOYARK: 'Красноярск',
  // OMSK: 'Омск',
  // SARATOV: 'Саратов',
  // UFA: 'Уфа',
}

export const COUNTRY_CODES = {
  7: '+7',
  374: '+374',
  375: '+375',
  996: '+996',
  373: '+373',
  992: '+992',
  998: '+998'
}

export const getMomentObject = (date) => {
  return moment(date)
}

// Функция для добавления дат в appointmentDates
export function addDatesToArray(startDate, endDate, datesArray) {
  let currentDate = moment(startDate);
  let endMoment = endDate ? moment(endDate) : currentDate;

  while (currentDate.isSameOrBefore(endMoment)) {
    datesArray.push(currentDate.clone());
    currentDate.add(1, "day");
  }

  return datesArray.sort((a, b) => a - b); // Сортируем массив дат
}

// Функция для исключения дат из appointmentDates
export function excludeDatesFromArray(startDate, endDate, datesArray) {
  let excludeStart = moment(startDate);
  let excludeEnd = endDate ? moment(endDate) : excludeStart;

  return datesArray.filter(date => !(date.isSameOrAfter(excludeStart) && date.isSameOrBefore(excludeEnd)));
}

// Функция для генерации периодов
export function generatePeriods(dates) {
  let periods = [];
  if (dates.length === 0) return periods;

  let startPeriod = dates[0];

  for (let i = 1; i < dates.length; i++) {
    if (!dates[i].isSame(dates[i - 1].clone().add(1, "day"), "day")) {
      periods.push({ start: startPeriod, end: dates[i - 1] });
      startPeriod = dates[i];
    }
  }
  periods.push({ start: startPeriod, end: dates[dates.length - 1] });

  return periods;
}

export const compareObjects = (a, b) => { // result - values of A
  let result = {}
  Object.keys(a).forEach(key => {
    if (a[key] !== b[key]){
      result[key] = a[key]
    }
  });
  return result
}


