import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { CREATE_APPLICATION_STEPS } from '../../components/modals/createApplication/utils'
import { getApplications } from '../../api/application';
import { showErrorMsg } from './alertSlice';


export const getAllApplicationsAsync = createAsyncThunk(
    "GET",

    async (queryObj = {}, {rejectWithValue, getState, dispatch}) => {
        const state = getState();
        const token = state.userSlice.user.access_token;


        const response = await getApplications(token, queryObj)

        if (!response.ok) {
            
            return dispatch(showErrorMsg('Произошла ошибка на сервере'))
        }

        const data = await response.json()
        return data;
    },
);

const initialApplication = {
    bulkApplicationData: [],
    note: null,
    isLoading: false,
    edit: {
        is: false,
        data: null
    },
    step: CREATE_APPLICATION_STEPS.PASSPORT_OR_FILE,
    travelDaysToAdditionalCity: null,
    visaCategoryCodes: [],
    desiredAppointmentDates: [],
    phoneCountryCode: null,
    phoneNumber: '', 
    priorityLevel: null,
    passportId: null,
    appointmentCity: null,
    additionalCity: null,
    passport: {
        lastName: '',
        firstName: '',
        birthDate: '',
        gender: null,
        citizenship: 'RUS',
        number: '',
        expirationDate: '',
    }
}

const initialApplications = {
    currentPage: 1,
    totalPages: 1,
    total: 0,
    perPage: 20,
    loaded: false,
    isLoading: false,
    items : []
}

export const applicationsSlice = createSlice({
    name: 'applications',
    initialState: {
        application: initialApplication,
        applications: initialApplications
    },
    reducers: {
        setApplication: (state, action) => {
            state.application = { ...state.application, ...action.payload }
        },
        setPassport: (state, action) => {
            state.application.passport = action.payload
        },
        setStep: (state, action) => {
            state.application.step = action.payload
        },
        stepBack: (state, action) => {
            let result = CREATE_APPLICATION_STEPS.PASSPORT_OR_FILE
            if (state.application.step === CREATE_APPLICATION_STEPS.INTERVALS) {
                result = CREATE_APPLICATION_STEPS.APPLICATION_SINGLE
            }
            state.application.step = result
        },
        updateList: (state, action) => {
            const application = action.payload
            state.applications.items = state.applications.items.map(el => {
                if (el.id === application.id) {
                    return application
                }
                return el
            })
        },
        setSearchParams: (state, action) => {
            state.applications = { ...state.applications, ...action.payload }
        },
        clearApplication: (state, action) => {
            state.application = initialApplication
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getAllApplicationsAsync.pending, (state, action) => {
                state.applications.isLoading = true
            })
            .addCase(getAllApplicationsAsync.fulfilled, (state, action) => {
                console.log(action.payload)
                if (action.payload.items?.length > 0){
                    state.applications = {
                        ...state.applications,
                        ...action.payload,
                        items: action.payload.currentPage === 1 ? action.payload.items : [...state.applications.items, ...action.payload.items],
                    }
                } else {
                    console.log(1)
                    state.applications.currentPage =1
                    state.applications.totalPages = 1
                    state.applications.total = 0
                    state.applications.items = []
                }
                state.applications.isLoading = false
                state.applications.loaded = true
            })
    }
})

// Action creators are generated for each case reducer function
export const { setApplication, setPassport, setStep, stepBack, updateList, clearApplication, setSearchParams } = applicationsSlice.actions;

export default applicationsSlice.reducer;