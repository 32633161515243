import { useNavigate } from "react-router"
import { COLORS } from "../../utils"

export const SpamPage = () => {
    const navigate = useNavigate()
    
    return <div className="m-auto py-5 h-50 justify-content-center text-center">
            <p style={{ color: COLORS.primary, fontSize: '60px', fontWeight: '600' }}>Слишком частые запросы :(</p>
            <p style={{ color: COLORS.text, fontSize: '23px', fontWeight: '500' }}>Подождите немного, позже обновите страницу</p>
            {/* <p className="mb-5" style={{ color: COLORS.text, fontSize: '23px', fontWeight: '500' }}>Такой страницы не существует, либо мы опять где-то накосячили, эх...</p> */}
            <button onClick={ () => navigate('/') } className="btn btn-md p-3 mx-auto mt-3"  style={{ maxWidth: '250px', backgroundColor: COLORS.secondary, color: COLORS.primary }}>Перейти на главную</button>
        </div>
}