import { Stack } from "react-bootstrap";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Auth } from "./screens/auth/Auth";
import { Header } from "./components/Header";
import { clearUser } from "./store/slices/userSlice";
import { useEffect } from "react";
import { Loader } from "./ui/Loader";
import { useDispatch } from "react-redux";
import { SpamPage } from "./screens/system/SpamPage";
import { Account } from "./screens/account/Account";
import { MAX_WIDTH } from "./utils";
import './buttons.scss';
import { NotFoundPage } from "./screens/system/NotFoundPage";
import { ErrorMsg, SuccessMsg } from "./components/Alerts";



function App() {
  return <Stack style={{minHeight: '100vh'}}>
    <div className="mx-auto w-100" style={{ maxWidth: MAX_WIDTH }}>
      <SuccessMsg />
      <ErrorMsg />
      <Header />
      <hr />
      <Routes>
        <Route path="/*" element={<Account />}/>
        <Route path="/auth" element={<Auth />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='spam' element={<SpamPage />}/>
        <Route path='*' element={<NotFoundPage />}/>

      </Routes>
    </div>
  </Stack>
}

const Logout = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
    dispatch(
      clearUser()
    )
		
		setTimeout(() => {
      navigate('/auth')

		}, 100)
	})
	return <div style={{ height: '70vh' }} className="d-flex w-100"><Loader /></div>
}

export default App;
