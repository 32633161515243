import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { showErrorMsg } from '../store/slices/alertSlice';
import { setUser } from '../store/slices/userSlice';
import { LogoutModal } from './modals/LogoutModal';
import { COLORS, PASTEL_COLORS } from '../utils';
import { getMe } from '../api/account';

let i = Math.floor(Math.random() * PASTEL_COLORS.length);

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loc = useLocation();
  const user = useSelector((state) => state.userSlice.user);
  const [state, setState] = useState({
    logoutModalShown: false,
  });

  useEffect(() => {
    const navbarCollapse = document.getElementById('responsive-navbar-nav');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  }, [loc.pathname]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getMe(user.access_token);
        if (response === null) {
          dispatch(showErrorMsg('Произошла ошибка!'));
          navigate('/logout');
          return;
        }
        if (response.status === 429) {
          dispatch(showErrorMsg('Слишком частые запросы'));
          navigate('/spam');
          return;
        }
        if (response.status === 401) return navigate('/logout');

        if (!response.ok) {
          dispatch(showErrorMsg('Пользователь не найден!'));
          navigate('/logout');
          return;
        }
        const data = await response.json();

        dispatch(
          setUser({
            ...data,
            loaded: true,
            access_token: user.access_token,
          })
        );
      } catch (e) {
        dispatch(showErrorMsg('Ошибка при загрузке данных!'));
        navigate('/logout');
      }
    }

    if (user.access_token !== undefined) {
      fetchData();
    } else {
      dispatch(
        setUser({
          ...user,
          loaded: true,
        })
      );
    }
  }, [user.access_token]);


  return (
    <div className='py-2' style={{ transition: 'none', outline: 'none' }}>
      <LogoutModal shown={state.logoutModalShown} setState={setState} />
      <Navbar collapseOnSelect expand='md'>
        <Navbar.Brand className='me-5'>
          <Nav.Link eventKey='1'>
            <div className='btn primary px-5 fs-5 py-2' style={{ cursor: 'pointer', }}>
              <p style={{ marginTop: '-3px' }}>
                  визы
              </p>
            </div>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          bsPrefix='no_outline'
          className='d-block d-md-none'
        >
          <img
            src={'https://resource.hurtle.ru/menu.svg'}
            style={{ height: '30px' }}
            alt=''
          />
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto mt-md-0 mt-3'>
            {user.access_token ?
                <AccountBar pathname={loc.pathname} /> : <Nav.Link
                className='pe-4 fs-6'
                onClick={() => navigate('/auth')}
                eventKey='4'
                style={{
                  color: 'black',
                  fontWeight: '300',
                  outline: 'none',
                }}
              >
                Авторизация
              </Nav.Link>}
          </Nav>
          <Nav>
            {user.access_token !== undefined &&
              <AccountRightBar
                user={user}
                setState={setState}
                pathname={loc.pathname}
              />}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};


  
const AccountBar = ({ pathname }) => {
    const navigate = useNavigate();
    return (
      <>
        {/* <Nav.Link
          className='pe-4 fs-6 fw-normal'
          onClick={() => navigate('/')}
          eventKey='2'
          style={{
            outline: 'none',
          }}
        >
          Главное
        </Nav.Link>
        <Nav.Link
          className='pe-4 fs-6 fw-normal'
        //   onClick={() => navigate('/vacancies')}
          eventKey='3'
          style={{
            outline: 'none',
          }}
        >
          Аналитика
        </Nav.Link> */}
      </>
    );
  };
  
  
const AccountRightBar = ({ user, setState, pathname }) => {
  const navigate = useNavigate();
  return !user.loaded || !user.id ? (
    <AccountRightBarPlaceholder />
  ) : (
    <div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start'>
      <div className='d-flex flex-column me-3 mt-1'>
        <p
          className='text-wrap text-end align-items-end d-none d-md-block'
        >
          {user.name}
        </p>
        <Nav.Link
          style={{ color: COLORS.primary }}
          className='p-0 text-decoration-none align-self-end text-nowrap my-auto'
          eventKey='10'
          onClick={() => setState((p) => ({ ...p, logoutModalShown: true }))}
        >
          Выйти
        </Nav.Link>
      </div>
      <div className='my-auto me-md-0 me-3'>
        <Nav.Link
          className='d-flex lh-lg align-items-center justify-content-center'
          eventKey='11'
          style={{
            fontSize: '18px',
            backgroundColor: PASTEL_COLORS[i],
            width: '50px',
            height: '50px',
            borderRadius: '15px',
            cursor: 'pointer',
            color: '#000',
          }}
        >
          {user.name[0]}
        </Nav.Link>
      </div>
    </div>
  );
};

const AccountRightBarPlaceholder = () => {
  const navigate = useNavigate();
  return (
    <div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start placeholder-wave'>
      <div className='d-flex flex-column me-3 mt-1' style={{ width: '5rem' }}>
        <p className='text-end align-items-end d-none d-md-block w-100'>
          <span className='placeholder col-12'></span>
        </p>
        <div
          style={{ color: COLORS.primary }}
          className='p-0 text-decoration-none align-self-end text-nowrap my-auto w-75'
        >
          <span className='placeholder col-12'></span>
        </div>
      </div>
      <div className='my-auto me-md-0 me-3'>
        <div
          onClick={() => navigate('/account')}
          className='d-flex lh-lg align-items-center justify-content-center placeholder'
          style={{
            fontSize: '18px',
            width: '50px',
            height: '50px',
            borderRadius: '15px',
            cursor: 'pointer',
            color: '#000',
          }}
        ></div>
      </div>
    </div>
  );
};


