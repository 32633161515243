import { useState } from "react";
import { AppInput } from "../../../../ui/AppInput";
import { AppSelect } from "../../../../ui/AppSelect";
import { AppSelectCheckBox } from "../../../../ui/AppSelectCheckbox";
import { AVAILABLE_CITIES, COUNTRY_CODES, CREATE_APPLICATION_STEPS, PRIORITY_LEVELS, VISA_TYPES } from "../utils";
import { setApplication, setStep } from "../../../../store/slices/applications";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMsg } from "../../../../store/slices/alertSlice";
import { validationApplicationSchema } from "../../../../yup";
export const FillApplication = () => {
  const application = useSelector(state => state.applicationsSlice.application)

  const dispatch = useDispatch()

  const [data, setData] = useState({
    appointmentCity: application.appointmentCity,
    phoneCountryCode: application.phoneCountryCode,
    phoneNumber: application.phoneNumber,
    visaCategoryCodes: application.visaCategoryCodes,
    additionalCity: application.additionalCity,
    priorityLevel: application.priorityLevel,
    travelDaysToAdditionalCity: application.travelDaysToAdditionalCity,
  })
  let suggestions = []
  if (data.visaCategoryCodes.length === 0)
    suggestions = Object.keys(VISA_TYPES.short_term)  // [...Object.keys(VISA_TYPES.short_term), ...Object.keys(VISA_TYPES.long_term)]
  else if (Object.keys(VISA_TYPES.long_term).filter(key => data.visaCategoryCodes.indexOf(key) >= 0).length > 0)
    suggestions = Object.keys(VISA_TYPES.long_term).filter(key => data.visaCategoryCodes.indexOf(key) < 0)
  else suggestions = Object.keys(VISA_TYPES.short_term).filter(key => data.visaCategoryCodes.indexOf(key) < 0)

  const handleVisaCodes = (c) => {
    if (data.visaCategoryCodes.indexOf(c) >= 0)
      return setData({ ...data, visaCategoryCodes: data.visaCategoryCodes.filter(el => el !== c) })
    return setData({ ...data, visaCategoryCodes:[ ...data.visaCategoryCodes, c] })
  }
  

  const saveHandler = async () => {
    const valid = await validationApplicationSchema.isValid(data)
    if (!valid || (data.additionalCity !== null && data.travelDaysToAdditionalCity === null)
      || (data.additionalCity === null && data.travelDaysToAdditionalCity !== null))
      return dispatch(showErrorMsg('Поля заполнены некорректно'))
    dispatch(setApplication(data))
    dispatch(setStep(CREATE_APPLICATION_STEPS.INTERVALS))
  }



  return  <div className="d-flex flex-column mx-auto" style={{ maxWidth: '500px' }}>
    <p className="fs-5 mb-3 text-center">Данные заявки</p>
    <div className="row row-cols-1 row-cols-md-2 gy-sm-3 gy-2 mb-sm-3 mb-2">
      <div className="col">
        <AppSelect label='Город подачи' value={data.appointmentCity} 
          options={[{value: null, label: 'Не выбрано'}, ...Object.keys(AVAILABLE_CITIES).filter(el => data.additionalCity !== el).map(el => ({ value: el, label: AVAILABLE_CITIES[el] }))]}
            onChange={v => setData({ ...data, appointmentCity: v })} />
      </div>
      <div className="col">
        <AppSelect label='Доп. город' 
        options={[{value: null, label: 'Не выбрано'}, ...Object.keys(AVAILABLE_CITIES).filter(el => data.appointmentCity !== el).map(el => ({ value: el, label: AVAILABLE_CITIES[el] }))]}
          value={data.additionalCity} onChange={v => setData({ ...data, additionalCity: v})} />
      </div>
      <div className="col">
        <AppInput label='Дней до доп. города' value={data.travelDaysToAdditionalCity}
          onChange={v => setData({ ...data, travelDaysToAdditionalCity: !v ? null : (/\d+/.test(Number(v)) && Number(v) >= 0 ? v : data.travelDaysToAdditionalCity) })} />
      </div>
      <div className="col">
        <AppSelect options={[{value: null, label: 'Не выбрано'},...Object.keys(PRIORITY_LEVELS).map(el => ({ label: PRIORITY_LEVELS[el], value: el }))]} 
          label='Приоритет' value={data.priorityLevel} onChange={v => setData({ ...data, priorityLevel: v})} />
      </div>
    </div>
    <AppSelectCheckBox className="mb-sm-3 mb-2" chosenList={data.visaCategoryCodes} suggestions={suggestions} onChoose={handleVisaCodes}
      label='Вид визы' />
    <div className="input-group">
      <AppSelect 
        className="w-25"
        selectClassName="p-sm-3 py-2" 
        options={[{label:'Не выбрано', value: null}, ...Object.keys(COUNTRY_CODES).map(el => ({ value: el, label: COUNTRY_CODES[el] }))]} 
        label='Код страны' value={data.phoneCountryCode}
        onChange={v => setData({ ...data, phoneCountryCode: v })} />
      <AppInput label='Телефон' className="w-75" value={data.phoneNumber} inputClassName='' 
        onChange={v => setData({ ...data, phoneNumber: v })} />
    </div>
    <button className="btn primary px-4 py-2 ms-auto mt-sm-3 mt-2" onClick={saveHandler}>Продолжить</button>
  </div>
}
