import { Alert } from "react-bootstrap";
import { hideErrorMsg, hideSuccessMsg } from "../store/slices/alertSlice";
import { useEffect } from "react";
import { ReactComponent as SuccessSvg } from '../icons/check_line.svg';
import { ReactComponent as FailSvg } from '../icons/fail.svg';
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../utils";

export const ErrorMsg = () => {
    const dispatch = useDispatch();
    const message = useSelector((state) => state.alertSlice.alert.errorMsg);
    const hideHandler = () => dispatch(hideErrorMsg());
    useEffect(() => {
      const timeout = setTimeout(hideHandler, 3000);
      return () => clearTimeout(timeout);
    }, [message]);
    return (
      <div
        className='position-fixed'
        style={{
          maxWidth: '500px',
          top: '10px',
          right: '10px',
          zIndex: '999999',
        }}
      >
        <Alert
          show={Boolean(message)}
          variant='danger'
          className='bg-white border-0'
          style={{ filter: 'drop-shadow(10px 10px 10px #F1F1F1)' }}
          onClose={() => dispatch(hideErrorMsg())}
          dismissible
        >
          <div className='d-flex me-sm-5'>
            <FailSvg className='me-2' height='24px' width='24px' />
            <p className='fs-5' style={{ color: COLORS.red_primary }}>
              Ошибка
            </p>
          </div>
          <p
            className='fs-6'
            style={{ color: COLORS.header_text, whiteSpace: 'pre-wrap' }}
          >
            {message}
          </p>
        </Alert>
      </div>
    );
  };
  
  export const SuccessMsg = () => {
    const dispatch = useDispatch();
    const message = useSelector((state) => state.alertSlice.alert.successMsg);
    const hideHandler = () => dispatch(hideSuccessMsg());
    useEffect(() => {
      const timeout = setTimeout(hideHandler, 3000);
      return () => clearTimeout(timeout);
    }, [message]);
    return (
      <div
        className='position-fixed'
        style={{
          maxWidth: '500px',
          top: '10px',
          right: '10px',
          zIndex: '999999',
        }}
      >
        <Alert
          show={Boolean(message)}
          variant='success'
          className='bg-white border-0'
          style={{ filter: 'drop-shadow(10px 10px 10px #F1F1F1)' }}
          onClose={() => dispatch(hideSuccessMsg())}
          dismissible
        >
          <div className='d-flex me-sm-5'>
            <SuccessSvg
              className='me-2'
              style={{ flexShrink: '0' }}
              fill={COLORS.success}
              height='24px'
              width='24px'
            />
            <p className='fs-5' style={{ color: COLORS.success }}>
              Успешно!
            </p>
          </div>
          <p className='fs-6' style={{ color: COLORS.header_text }}>
            {message}
          </p>
        </Alert>
      </div>
    );
  };