import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import alertSlice from './slices/alertSlice';
import applicationsSlice from './slices/applications';

export default configureStore({
  reducer: {
    userSlice: userSlice,
    alertSlice: alertSlice,
    applicationsSlice: applicationsSlice,
  },

});
