import { useEffect, useState } from "react";
import { AppCheckbox } from "./AppCheckbox"
import { VISA_TYPES } from "../components/modals/createApplication/utils";

export const AppSelectCheckBox = ({ label, chosenList = [], onChange, onChoose, suggestions, selected, value, data, loaded = true, disabled = false, style = {}, className='', ...props}) => {
    const [shown, setShown] = useState(false)
    return <div className={'' + className} style={{ ...style, position: 'relative', zIndex: 1 }} {...props}>
        <ul className="list-group position-relative" style={{ listStyleType: 'none', }}>
            
            <li className="list-group-item d-flex p-0 form-floating mb-0">
                <input onChange={() => {}} onClick={() => setShown(!shown)} value={`Выбрано: ${chosenList.length}`} style={{ border: 'none', cursor: 'pointer', outline: 'none' }}
                    className="form-control fw-lighter bg-white" id="floatingInput" />
                <label htmlFor="floatingInput" style={{ fontWeight: '200', marginTop: '-2px' }}>{label}</label>
            </li>
            <li className="w-100" style={{  }}>
                <div className="position-absolute bg-white w-100" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    { 
                        shown && chosenList.map((el, i) => (
                        <div className="list-group-item d-flex p-2 w-100" key={i} onClick={e => onChoose(el)} style={{ cursor: 'pointer' }} >
                            <p className="me-auto fs-6 fw-lighter pe-1 ">{VISA_TYPES.long_term[el] ? VISA_TYPES.long_term[el] : VISA_TYPES.short_term[el]}</p> 
                            <AppCheckbox checked={true} margin="me-0" onChange={() => {}} />
                        </div>
                    ))}
                    { 
                        shown && suggestions.map((el, i) => (
                        <div className="list-group-item d-flex p-2" key={i} onClick={e => onChoose(el)} style={{ cursor: 'pointer' }}>
                            <p className="me-auto fs-6 fw-lighter pe-1" >{VISA_TYPES.long_term[el] ? VISA_TYPES.long_term[el] : VISA_TYPES.short_term[el]}</p> 
                            <AppCheckbox checked={false} margin="me-0" onChange={() => {}} />
                        </div>
                    ))}

                </div>

            </li>
        </ul>
    </div>
}
