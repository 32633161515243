// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #0094FF;
  --primary-hover: #0075C9;
  --danger: #FF4E4E;
  --danger-hover: #CB3E3E;
  --success: #8FC466;
  --warning: #F28304;
  --warning-hover: #CB6C00;
}

body {
  margin: 0;
}


* {
  font-family: 'geologica', sans-serif;
  transition: 0.3s;
}

p {
  margin: 0;
  padding: 0
}

input, textarea, select, button {
  outline: none;
  box-shadow: none !important;
}

button {
  border: 0 none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,SAAS;AACX;;;AAGA;EACE,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT;AACF;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[":root {\n  --primary: #0094FF;\n  --primary-hover: #0075C9;\n  --danger: #FF4E4E;\n  --danger-hover: #CB3E3E;\n  --success: #8FC466;\n  --warning: #F28304;\n  --warning-hover: #CB6C00;\n}\n\nbody {\n  margin: 0;\n}\n\n\n* {\n  font-family: 'geologica', sans-serif;\n  transition: 0.3s;\n}\n\np {\n  margin: 0;\n  padding: 0\n}\n\ninput, textarea, select, button {\n  outline: none;\n  box-shadow: none !important;\n}\n\nbutton {\n  border: 0 none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
