import * as yup from 'yup';
import { AVAILABLE_CITIES, GENDERS, PRIORITY_LEVELS, VISA_TYPES } from './components/modals/createApplication/utils';

const citiesSchema = yup.string().oneOf(Object.keys(AVAILABLE_CITIES)).min(3).max(255).defined()

export const validationPassportSchema = yup.object().shape({
    number: yup.string().min(5).max(15).defined(),
    firstName: yup.string().min(3).max(50).defined(),
    lastName: yup.string().min(3).max(50).defined(),
    expirationDate: yup.date().defined().min(new Date(1900, 0)).max(new Date().getFullYear() + 50),
    birthDate: yup.date().defined().min(new Date(1900, 0)).max(new Date().getFullYear()),
    gender: yup.mixed().oneOf(Object.keys(GENDERS)).defined(),
    citizenship: yup.string().min(3).max(150).defined(),
});

export const validationApplicationSchema = yup.object().shape({
    appointmentCity: citiesSchema,
    additionalCity: citiesSchema.nullable(),
    travelDaysToAdditionalCity: yup.string().max(355).defined().nullable(),
    visaCategoryCodes: yup.array().of(
      yup.string().oneOf([...Object.keys(VISA_TYPES.long_term), ...Object.keys(VISA_TYPES.short_term)]).min(3).max(255).defined(),
    ).defined(),
    phoneCountryCode: yup.string().defined().min(1).max(5),
    phoneNumber: yup.string().defined().min(3).max(255),
    priorityLevel: yup.string().oneOf(Object.keys(PRIORITY_LEVELS)).defined(),
});

export const validationIntervalsSchema = yup.object().shape({
    desiredAppointmentDates: yup.array().of(
        yup.date().defined().min(new Date(1900, 0)).max(new Date().getFullYear() + 3),
      ).min(1).defined(),
    note: yup.string().nullable().defined(),
});