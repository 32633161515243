import { Modal } from "react-bootstrap";
import { COLORS } from "../../../utils";
import { Navigate } from "react-router-dom";
import {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FillApplication } from "./components/FillApplication";
import { IntervalsList } from "./components/IntervalsList";
import { CreateSinglePassport } from "./components/CreateSinglePassport";
import { FilePreview, UploadApplicationsList } from "./components/CreateMultipleApplications";
import { CREATE_APPLICATION_STEPS } from "./utils";
import { clearApplication, setStep, stepBack } from "../../../store/slices/applications";


export const CreateApplicationsModal = ({ shown, set }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStep(CREATE_APPLICATION_STEPS.PASSPORT_OR_FILE))
  },[])
  const application = useSelector(state => state.applicationsSlice.application)
  const handleBack = () => {
    dispatch(stepBack())
  }
  return (
    <Modal
      dialogClassName='rounded-4'
      centered
      show={shown}
      size="xl"
      onHide={() => dispatch(clearApplication()) && set((p) => ({ ...p, createModalShown: false }))}
    >
      <Modal.Header closeButton >
        <Modal.Title className="fw-light">{application.step === CREATE_APPLICATION_STEPS.FILE_PREVIEW ? 'Импорт заявок' : 'Новая заявка'}</Modal.Title>
        {application.step !== CREATE_APPLICATION_STEPS.PASSPORT_OR_FILE && <button onClick={handleBack} className="btn warning py-1 px-4 ms-4">Назад</button>}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column p-3">
          {application.step === CREATE_APPLICATION_STEPS.PASSPORT_OR_FILE ? <PassportOrFile /> : 
            application.step === CREATE_APPLICATION_STEPS.FILE_PREVIEW ? <FilePreview onCloseModal={() => set((p) => ({ ...p, createModalShown: false }))} /> :
              application.step === CREATE_APPLICATION_STEPS.APPLICATION_SINGLE ? <FillApplication /> :
              application.step === CREATE_APPLICATION_STEPS.INTERVALS ? <IntervalsList onCloseModal={() => set((p) => ({ ...p, createModalShown: false }))} /> : <Navigate to='/' />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const PassportOrFile = () => {
  const application = useSelector(state => state.applicationsSlice.application)
  const [state,setState] = useState({
    isSingle: true,
  })
  return <>
  <div className="d-flex fs-6 fw-light">
    <div 
      className="rounded px-4 py-2" 
      style={{ backgroundColor: state.isSingle ? COLORS.gray : 'white', cursor: 'pointer' }} 
      onClick={() => setState({ ...state, isSingle: true })}>
      <p className="text-center m-auto">Индивидуально</p>
    </div>
    {!application.edit.is && <div 
      className="rounded px-4 py-2" 
      style={{ backgroundColor: !state.isSingle ? COLORS.gray : 'white', cursor: 'pointer' }} 
      onClick={() => setState({ ...state, isSingle: false })}>
      <p className="text-center m-auto">Импорт списка</p>
    </div>}

  </div>
  <hr className="my-4"/>
  {state.isSingle ? <CreateSinglePassport /> : <UploadApplicationsList />}
</>
}





