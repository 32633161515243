import { useEffect, useState } from "react";

export const MAX_WIDTH = '1500px'

export const COLORS = {
    primary: '#0094FF',
    primary_light: '#D9EFFF',
    danger: '#FF4E4E',
    gray: '#F2F2F2',
    dark_gray: '#9F9F9F',
    warning_light: '#F7B568',
    warning: '#F28304',
    success: '#8FC466'
}
export const PASTEL_COLORS = ['#FFFCB0', '#D4FFC0', '#D1F4FF', '#FDD0B7'];

export const toTime = (datetime) => {
    return new Date(datetime).toLocaleTimeString().slice(0, 5);
};

export const SOURCE = {
  api_url: (process.env.REACT_APP_DOMAIN || "http://localhost:5505") + '/api/v1/'
}

export const toDate = (datetime) => {
  return new Date(datetime).toLocaleDateString();
};

export const prettyDateToISO = (date) => {
  let result = date.split('.').reverse().join('-')
  return result + 'T00:00:00.000Z'
}

export const isCorrectDate = (dateStr) => {
  return !isNaN(new Date(dateStr));
}


const rangeValidate = (s, min, max) => s >= min && s < max;

export const Validation = {
    
  validMail: (mail) =>
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    ), // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
  validPassword: (pswd) => pswd.length >= 8, // /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(pswd)
  validDateTime: (date) => date.length === 20,
  validDate: (date) =>
    (date.length === 10 && !date.includes('_')) 
    // && rangeValidate(+date.split('-')[0], 1900, new Date().getFullYear() - 13) 
};

export const useDebounceCallback = (func, delay) => {
	const [args, setArgs] = useState(null)
	useEffect(() => {
		if (args === null) return
		const timeout = setTimeout(() => func(...args), delay)
		return () => clearTimeout(timeout)
	}, [args]) // DO NOT add func and delay to deps
	return (...args) => setArgs(args)
}