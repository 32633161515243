import { Loader } from '../../ui/Loader'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { AppInput } from '../../ui/AppInput';
import { showErrorMsg } from '../../store/slices/alertSlice';
import { setAuth } from '../../store/slices/userSlice';
import { Navigate } from 'react-router-dom';
import {
  createAccessToken,
} from '../../api/auth';
import { COLORS, Validation } from '../../utils';

export const Auth = () => {
  const user = useSelector(state => state.userSlice.user)
  const [state, setState] = useState({
    login: '',
    password: '',
    isLoading: false
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (user.access_token) return <Navigate to='/' />

  const loginHandler = async () => {
    setState({ ...state, isLoading: true})
    await login(state, navigate, dispatch)
    setState({ ...state, isLoading: false})
  }



  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className='w-100 d-flex flex-column mx-auto justify-content-center'
      style={{ maxWidth: '500px', height: '70vh' }}
    >
    <p className='text-center fs-1 mb-3 fw-normal'>
        Вход
    </p>
      
    <AppInput
      className='mb-sm-3 mb-2'
      label='логин'
      value={state.login}
      showAlert={state.login === ''}
      onChange={(v) => setState((prev) => ({ ...prev, login: v }))}
    />
    
    <AppInput
        className='mb-sm-3 mb-2'
        label='пароль'
        type='password'
        value={state.password}
        showAlert={!Validation.validMail(state.password)}
        onChange={(v) => setState((prev) => ({ ...prev, password: v.trim() }))}
    />
       
      <div className='d-flex flex-row flex-wrap-reverse align-items-center mt-3 gap-2'>  
     
       
        
        
        <button
          className='btn py-2 px-4 rounded-3 primary ms-auto'
          onClick={() => loginHandler()}
          style={{ width: '180px' }}
        >
          {state.isLoading ? <Loader size='small' color='white' /> : state.hasAccount ? 'Войти' : 'Продолжить'}
        </button>
      </div>
    </form>
  );
};




async function login(state, navigate, dispatch) {
  if (!(state.login && state.password)){
    return dispatch(showErrorMsg('Заполните все поля'))
  }
  try {
    const response = await createAccessToken({password: state.password, login: state.login})
    if (!response.ok) {
      if (response.status === 401) return dispatch(showErrorMsg('Неверный логин или пароль'))
      return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    }
    const { token } = await response.json()
    if (!token) return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    dispatch(setAuth({ access_token: token }))
    navigate('/')
  } catch {
    return dispatch(showErrorMsg('Произошла ошибка при загрузке данных'))
  }
}
