import { COLORS } from "../utils.js"

export const AppCheckbox = ({  onChange, className = '', checked, margin = 'me-sm-4 me-3', disabled = false, fs = '' }) => {
    return <div className={'d-flex flex-row flex-nowrap align-items-center ' + className}>
        <div className={'d-inline-block my-auto' + margin}>
            <input className="form-check-input m-0 my-auto" disabled={disabled}
                style={{ width:'20px', height:'20px', borderRadius: '5px', boxShadow: 'none', cursor: 'pointer' }} type="checkbox" checked={checked}
                onChange={e => onChange(e)}/>
        </div>
    </div>
}