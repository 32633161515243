import { useState } from "react";
import { COLORS, Validation, isCorrectDate, prettyDateToISO } from "../../../../utils"
import InputMask from 'react-input-mask';
import { addDatesToArray, compareObjects, excludeDatesFromArray, generatePeriods } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMsg } from "../../../../store/slices/alertSlice";
import { Loader } from "../../../../ui/Loader";
import { createPassport, patchPassport } from "../../../../api/passport";
import { clearApplication, setApplication, setSearchParams } from "../../../../store/slices/applications";
import { createApplication, patchApplication } from "../../../../api/application";
import { validationIntervalsSchema } from "../../../../yup";
import TextareaAutosize from "../../../../ui/TextareaAutosize";



export const IntervalsList = ({ onCloseModal }) => {
  const application = useSelector(state => state.applicationsSlice.application)
  const user = useSelector(state => state.userSlice.user)
  const [state, setState] = useState({
    note: '',
    startDate: '',
    endDate: '',
    periods: [], // generatePeriods(application.desiredAppointmentDates)
    desiredAppointmentDates: [] , // application.desiredAppointmentDates
    isLoading: false
  })

  
  const dispatch = useDispatch()

  const addIntervalHandler = (isAdd) => {
    let start = state.startDate.split('.').reverse().join('-')
    let end = state.endDate.split('.').reverse().join('-')
    if (!(isCorrectDate(start) && isCorrectDate(end)))
      return dispatch(showErrorMsg('Неправильный формат дат'))
    const result =  isAdd ? addDatesToArray(start, end, state.desiredAppointmentDates)
    : excludeDatesFromArray(start, end, state.desiredAppointmentDates)
    console.log(state.desiredAppointmentDates, result, start, end)
    setState({ ...state, startDate: '', endDate: '', desiredAppointmentDates: result, periods: generatePeriods(result) })
    
  }
  
  const deleteIntervalHandler = (delEl) => {
    const result = excludeDatesFromArray(delEl.start.format('YYYY-MM-DD'), delEl.end.format('YYYY-MM-DD'), state.desiredAppointmentDates)
    setState({ ...state, 
      periods: generatePeriods(result),
      desiredAppointmentDates: result })
  }

  const createApplicationHandler = async () => {
    const dates = state.desiredAppointmentDates.map(el => prettyDateToISO(el.format("DD.MM.YYYY")))
    const valid = await validationIntervalsSchema.isValid({ desiredAppointmentDates: dates, note: state.note })
    if (!valid) return dispatch(showErrorMsg('Выберите периоды'))
    const initialApplication = {
      appointmentCity: application.appointmentCity,
      phoneCountryCode: application.phoneCountryCode,
      phoneNumber: application.phoneNumber,
      priorityLevel: application.priorityLevel,
      visaCategoryCodes: application.visaCategoryCodes,
      additionalCity: application.additionalCity,
      travelDaysToAdditionalCity: application.travelDaysToAdditionalCity,
      desiredAppointmentDates: dates,
      note: state.note
    }

    const initialPassport = {
      ...application.passport,
      expirationDate: prettyDateToISO(application.passport.expirationDate),
      birthDate: prettyDateToISO(application.passport.birthDate)
    }

    dispatch(setApplication({ isLoading: true }))

    if (application.edit.is) {
      let result = compareObjects(initialPassport, application.edit.data.passport)
      if (Object.keys(result).length > 0){
        const passportResponse = await patchPassport(user.access_token, result, application.passportId)
        if (!passportResponse.ok) {
          dispatch(setApplication({ isLoading: false }))
          return dispatch(showErrorMsg('Ошибка при изменении паспорта'))
        }
      }
      result = compareObjects(initialApplication, application.edit.data)
      const applicationResponse = await patchApplication(user.access_token, {
        ...result,
        desiredAppointmentDates: dates,
      }, application.id)
      if (!applicationResponse.ok) {
        dispatch(setApplication({ isLoading: false }))
        return dispatch(showErrorMsg('Ошибка при изменении заявки'))
      }
      
    }
    else {
      const passportResponse = await createPassport(user.access_token, initialPassport)
  
      if (!passportResponse.ok) {
        dispatch(setApplication({ isLoading: false }))
        return dispatch(showErrorMsg('Ошибка при создании паспорта'))
      }
      const passRes = await passportResponse.json()
  
      dispatch(setApplication({ passportId: passRes.id }))
      
      const applicationResponse = await createApplication(user.access_token, {
        passportId: passRes.id, 
        desiredAppointmentDates: dates,
        ...initialApplication
      })
      if (!applicationResponse.ok) {
        dispatch(setApplication({ isLoading: false }))
        return dispatch(showErrorMsg('Ошибка при создании заявки'))
      }
    }
    onCloseModal()
    dispatch(setSearchParams({ currentPage: null }))
    dispatch(clearApplication())
  }
  
  

  return <>
    <p className="fs-5 fw-light mb-3">Даты</p>
    <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 gy-2 gy-sm-3 mb-3">
      <div className="col">
        <div className="form-floating">
          <InputMask 
            mask='99.99.9999'
            id="floatingInput" 
            placeholder='Начало диапазона' 
            className={"form-control rounded-3 "} 
            value={state.startDate} 
            onChange={(e,v) => setState({ ...state, startDate: v ? v : e.target.value })}
            onClick={e => e.preventDefault()}/>
          <label htmlFor="floatingInput" className='fs-6' style={{ fontWeight: '200', marginTop: '-2px' }}>Начало диапазона</label>
        </div>
      </div>
      <div className="col">
        <div className="form-floating">
          <InputMask 
            mask='99.99.9999'
            id="floatingInput" 
            placeholder='Конец диапазона' 
            className={"form-control rounded-3 "} 
            value={state.endDate} 
            onChange={(e,v) => setState({ ...state, endDate: v ? v : e.target.value })}
            onClick={e => e.preventDefault()}/>
          <label htmlFor="floatingInput" className='fs-6' style={{ fontWeight: '200', marginTop: '-2px' }}>Конец диапазона</label>
        </div>
      </div>
      <div className="col">
        <button className='btn primary py-3 w-100'
          disabled={!(Validation.validDate(state.startDate) && Validation.validDate(state.endDate))}
          onClick={() => addIntervalHandler(true)} >Добавить</button>
      </div>
      <div className="col">
        <button disabled={!(Validation.validDate(state.startDate) && Validation.validDate(state.endDate))}
        className='btn danger py-3 w-100' onClick={() => addIntervalHandler(false)} >Исключить</button>
      </div>
    </div>
    {state.periods
      .map((el, i) => <Interval data={el} key={i} onDelete={(el) => deleteIntervalHandler(el)} />)}

    <TextareaAutosize
      type='text'
      className='p-2 rounded-3 mt-5'
      placeholder='Заметки'
      minHeight='6rem'
      defaultHeight='1.5rem'
      defaultValue={application.note !== null ? application.note : ''}
      style={{ resize: 'none', minWidth: '100px' }}
      onChange={e => setState({ ...state, note: e.target.value || null })} />

    <button className="btn primary py-3 mx-auto mt-5 w-100"
      onClick={createApplicationHandler}
      disabled={state.desiredAppointmentDates.length === 0}
      style={{ maxWidth: '500px' }}
    >{application.isLoading ? <Loader color="white" size="small" /> : application.edit.is ? 'Сохранить изменения' : 'Создать заявку'}</button>
  </>
}
  
const Interval = ({data, onDelete}) => {
  return <>
    <hr />
    <div className="d-flex flex-row align-items-center justify-content-between px-4">
      <div className="d-flex flex-row flex-nowrap align-items-center">  
        <div className="me-4 rounded-1" style={{ flexShrink: '0', width: '20px', height: '20px', backgroundColor: COLORS.success }}></div>
        <p className="fs-6 my-auto">
          Можно подаваться
        </p>
      </div>
      <p className="fs-6">{data.start.format("DD.MM.YYYY") + (data.start !== data.end ?  ' — ' + data.end.format("DD.MM.YYYY") : '')}</p>
      <p className="fs-6" onClick={() => onDelete(data)} style={{ color: COLORS.danger, cursor: 'pointer' }}>Удалить</p>
      
    </div>
  </>
}