import { useEffect, useState } from "react"
import { AppInput } from "../../../ui/AppInput"
import { CreateApplicationsModal } from "../../../components/modals/createApplication/CreateApplications"
import { Loader } from "../../../ui/Loader"
import { useDispatch, useSelector } from "react-redux"
import { getAllApplicationsAsync, setSearchParams } from "../../../store/slices/applications"
import { COLORS, toDate } from "../../../utils"
import { AVAILABLE_CITIES, generatePeriods, getMomentObject } from "../../../components/modals/createApplication/utils"
import { APPLICATION_STAGES, useDebounceCallback } from "./utils"
import { ApplicationModal } from "../../../components/modals/ApplicationModal"
import { exportData } from "../../../api/application"
import { showErrorMsg } from "../../../store/slices/alertSlice"

export const Main = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        search: '',
        city: null,
        stage: null,
        isDataExporting: false,
        createModalShown: false,
        activeApplicationModal: {
            shown: false,
            data: null,
        }
    })

    const user = useSelector(state => state.userSlice.user)

    const applyParams = useDebounceCallback(
		() => dispatch(getAllApplicationsAsync(
            {
                include: ['passport', 'vfsAccount.vfsAppointment'], 
                page: applications.currentPage, 
                size: applications.perPage, 
                search: state.search, 
                stage: state.stage,
                city: state.city
             })),
		500
	)

    const handleExport = async () => {
        setState({ ...state, isDataExporting: true })
        try {
            const response = await exportData(user.access_token)
            if (!response.ok) return dispatch(showErrorMsg('Ошибка при загрузке файла. Попробуйте позже'))
            const blob = await response.blob()

            var url = window.URL.createObjectURL(blob);
    
            // Создаем ссылку для скачивания файла
            var a = document.createElement('a');
            a.href = url;
            a.download = 'export.xlsx'; // Имя для сохранения файла
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        } catch(e) {
            dispatch(showErrorMsg('Ошибка при загрузке файла. Попробуйте позже'))
        }
        setState({ ...state, isDataExporting: false })
    }

    const onOpenCreateModal = () => setState({ ...state, createModalShown: true })

    const applications = useSelector(state => state.applicationsSlice.applications)
    useEffect(() => {
        applyParams()
    }, [applications.currentPage, state.search, state.stage, state.city])

    return <div className="d-flex flex-column pt-3 pb-5">
        <CreateApplicationsModal shown={state.createModalShown} set={setState}  />
        {state.activeApplicationModal.shown && <ApplicationModal data={state.activeApplicationModal} set={setState} onOpenEditModal={onOpenCreateModal}  />}
        <div className="row">
            <div className="col" style={{ maxWidth: '300px' }}>
                <button className="btn primary rounded py-3 w-100 mb-4" onClick={onOpenCreateModal}>
                    новая заявка
                </button>

                <AppInput label='поиск' className="" value={state.search} onChange={v => {dispatch(setSearchParams({currentPage: 1,
                totalPages: 1,
                total: 0,
                perPage: 20
                })); setState({ ...state, search: v })}} />

                <p className="fs-5 fw-lighter mt-4 mb-3" style={{ color: COLORS.dark_gray }}>Статус</p>
                <Filter onClick={() => setState({ ...state, stage: null })} data={{ active: state.stage === null, label: 'Все' }} />
                {Object.keys(APPLICATION_STAGES).map((el, i) => 
                    <Filter key={i} onClick={() => setState({ ...state, stage: el })} data={{ active: state.stage === el, label: APPLICATION_STAGES[el] }} />)}

                <p className="fs-5 fw-lighter mt-4 mb-3" style={{ color: COLORS.dark_gray }}>Город</p>
                <Filter onClick={() => setState({ ...state, city: null })} data={{ active: state.city === null, label: 'Все' }} />
                {Object.keys(AVAILABLE_CITIES).map((el, i) => 
                    <Filter key={i} onClick={() => setState({ ...state, city: el })} data={{ active: state.city === el, label: AVAILABLE_CITIES[el] }} />)}

                <button className="btn primary rounded py-3 w-100 mt-4" onClick={handleExport}>
                    {state.isDataExporting ? <Loader size="small" color='white' /> : 'экспортировать'}
                </button>
            </div>
            <div className="col-auto">
                <div className="h-100" style={{ width: '1px', backgroundColor: '#ddd' }}>

                </div>
            </div>
            <div className="col d-flex flex-column">
                <div className="row">
                    <div className="col-1">
                        <p className="m-auto text-center">#</p>
                    </div>
                    <div className="col-2">
                        <p className="m-auto text-center">Фамилия</p>
                    </div>
                    <div className="col-2">
                        <p className="m-auto text-center">Номер паспорта</p>
                    </div>
                    <div className="col-3">
                        <p className="m-auto text-center">Даты записи</p>
                    </div>
                    <div className="col-4">
                        <p className="m-auto text-center">Статус заявки</p>
                    </div>
                </div>
                <hr className="mt-4" />
                { !applications.loaded ? <Loader className="mt-5" /> : <div><div className="d-flex flex-column"
                    style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh' }}>
                    {applications.items.length  ? 
                        applications.items.map((el, i) => 
                            <ApplicatonRow key={i} data={el} onOpenModal={data => setState(({ ...state, activeApplicationModal: {
                            shown: true,
                            data: data
                            } }))} />) :
                     <p className="text-center mt-4 fs-5">Заявок не создано</p>}
                </div></div>
                }
                <div className="d-flex flex-row align-items-center mt-3">
                    <p className="fw-light" style={{ color: COLORS.dark_gray }}>Показано {applications.items.length +  ' из ' + applications.total}</p>
                    {applications.total > applications.items.length && applications.items.length > 0 && 
                    <button className="btn py-2 px-5 fs-6 mx-auto w-100" 
                        onClick={() => dispatch(setSearchParams({ currentPage: applications.currentPage + 1 }))} 
                            style={{ backgroundColor: COLORS.gray, maxWidth: '250px' }}>{applications.isLoading ? <Loader size="small" /> : 'Загрузить ещё'}</button>}
                </div>
            </div>
        </div>
    </div>
}

const Filter = ({ data, onClick }) => {
    const borderLeft = data.active ? '2px solid ' + COLORS.primary : '1.5px solid ' + COLORS.primary_light
    return <div className="px-3 py-1 mb-3" onClick={onClick} style={{ borderLeft: borderLeft, cursor: 'pointer' }}>
        <p className="fs-6">
            {data.label}
        </p>
    </div>
}

const ApplicatonRow = ({ data, onOpenModal }) => {
    let periods = ''

    if (data.processingStage === 'VFS_APPOINTMENT_BOOKED' && data.vfsAccount?.appointmentUrn !== null) {
        periods = toDate(data.vfsAccount?.appointment?.dateTime) + ', ' + data.vfsAccount?.appointment?.slotInterval
    } else periods = generatePeriods(data.desiredAppointmentDates.map(el => getMomentObject(el)))
        .map(el => el.start.format("DD.MM") + ' – ' + el.end.format("DD.MM")).join(', ')
   
    return <div className="row mt-3 py-1 fw-light"
        onClick={() => onOpenModal(data)}
        style={{ cursor: 'pointer' }}>
        <div className="col-1 d-flex">
            <div className="me-4 rounded-1 m-auto" style={{ flexShrink: '0', width: '20px', height: '20px',
                backgroundColor: data.processingStage === 'VFS_APPOINTMENT_BOOKED' ? COLORS.success : 
                    (data.processingStage === 'CANCELED' ? COLORS.danger : COLORS.warning_light) }}></div>
        </div>
        <div className="col-2 d-flex">
            <p className="fs-6 text-center m-auto">{data.passport.lastName}</p>
        </div>
        <div className="col-2 d-flex">
            <p className="fs-6 text-center m-auto">{data.passport.number}</p>
        </div>
        <div className="col-3 d-flex">
            <p className="fs-6 text-center text-truncate m-auto">{periods}</p>
        </div>
        <div className="col-4 d-flex">
            <p className="fs-6 text-center m-auto" style={{ color: data.processingStage === 'WAITING_FOR_MANAGER_CONFIRMATION' ? COLORS.danger : '' }}>{APPLICATION_STAGES[data.processingStage]}</p>
        </div>
    </div>
}