import { Modal } from "react-bootstrap";
import { COLORS, toDate, useDebounceCallback } from "../../utils";
import { APPLICATION_STAGES } from "../../screens/account/main/utils";
import { AVAILABLE_CITIES, PRIORITY_LEVELS, VISA_TYPES, generatePeriods, getMomentObject } from "./createApplication/utils";
import { deleteApplication, downloadtAppointment, patchApplication, safePatchApplication } from "../../api/application";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMsg } from "../../store/slices/alertSlice";
import { clearApplication, setApplication, setSearchParams, updateList } from "../../store/slices/applications";
import TextareaAutosize from "../../ui/TextareaAutosize";
import { useEffect } from "react";

export const ApplicationModal = ({ data, set, onOpenEditModal }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.userSlice.user)
    const application = data?.data
    console.log(application)
    const isBooked = application?.processingStage === 'VFS_APPOINTMENT_BOOKED' && application?.vfsAccount.appointmentUrn
    const passport = application?.passport
    const handleDelete = async () => {
        if (!window.confirm('Вы действительно хотите отменить заявку?')) return 
        const response = await deleteApplication(user.access_token, application.id)
        if (!response.ok) return dispatch(showErrorMsg('Ошибка при отмене заявки.'))
        onCloseModal()
        dispatch(setSearchParams({ currentPage: null }))
        
        return
    }


    const handleDownload = async () => {
        try{
            const response = await downloadtAppointment(user.access_token, application.vfsAccount.appointmentUrn)
            if (!response.ok) return dispatch(showErrorMsg('Ошибка при загрузке файла. Попробуйте позже'))
            const blob = await response.blob()

            var url = window.URL.createObjectURL(blob);
    
            // Создаем ссылку для скачивания файла
            var a = document.createElement('a');
            a.href = url;
            a.download = [passport.lastName, passport.firstName, passport.number].join('_') + '.pdf'; // Имя для сохранения файла
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        } catch(e) {
            dispatch(showErrorMsg('Ошибка при загрузке файла. Попробуйте позже'))
        }
    }

    const handleApprove = async () => {
        // if (!window.confirm('Вы действительно хотите подтвердить заявку? Её нельзя будет редактировать или отменить.')) return
        const response = await patchApplication(user.access_token, { processingStage: 'WAITING_FOR_ORCHESTRATOR' }, application.id)
        dispatch(setApplication({ isLoading: true }))
        if (!response.ok) {
            dispatch(setApplication({ isLoading: false }))
            return dispatch(showErrorMsg('Ошибка при подтверждении заявки'))}
        dispatch(setApplication({ isLoading: false }))
        onCloseModal()
        dispatch(setSearchParams({ currentPage: null }))
    }

    const handleEdit = () => {
        if (!window.confirm('Вы уверены что хотите отредактировать заявку? Даты встреч могут быть сброшены!')) return
        dispatch(setApplication({ 
            edit: {
                is: true,
                data: application
            }, 
            ...application,
            desiredAppointmentDates: [],
            passport: {
                lastName: passport.lastName,
                firstName: passport.firstName,
                birthDate: toDate(passport.birthDate), 
                number: passport.number,
                citizenship: passport.citizenship,
                gender: passport.gender,
                expirationDate: toDate(passport.expirationDate)
            },
            passportId: passport.id,
        }))
        onOpenEditModal()
        onCloseModal()
    }

    const onCloseModal = () => {
        set((p) => ({ ...p, activeApplicationModal: {
            shown: false,
            data: null
        } }));
    }

    const handleChangeNotes = (v) => {
        changeNotes(v)
    }

    const changeNotes = useDebounceCallback(
		async v => {
			const response = await safePatchApplication(user.access_token, { note: v }, application.id)
            if (!response.ok) return dispatch(showErrorMsg('Ошибка'))
            dispatch(updateList({ ...application, note: v || null }))
        },
		500
	)



    return <Modal
        dialogClassName='rounded-4'
        centered
        size="lg"
        show={data.shown}
        onHide={onCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Заявка</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-column p-3">
                <p className="fs-5">
                    Заявитель
                </p>
                <p className="fw-light fs-6 mt-4">
                    {passport.firstName + ' ' + passport.lastName}
                    <span className="mx-3"></span>
                    {passport.gender[0]}
                    <span className="mx-3"></span>
                    {passport.citizenship}
                    <span className="mx-3"></span>
                    {toDate(passport.birthDate)}
                </p>
                <p className="fw-light fs-6 mt-3">
                    {passport.number}
                    <span className="mx-3"></span>
                    {toDate(passport.expirationDate)}
                </p>
                <p className="fs-5 mt-5">
                    Запись
                    <span className="ms-4 fw-lighter" style={{ color: application.processingStage === 'VFS_APPOINTMENT_BOOKED' ? COLORS.success : 
                            application.processingStage === 'CANCELED' ? COLORS.danger : COLORS.warning }}>
                        {APPLICATION_STAGES[application.processingStage]}
                    </span>
                </p>
                <div className="row my-4 gy-2">
                    <div className="col-md">
                        <p className="fw-light fs-6">
                            {AVAILABLE_CITIES[application.appointmentCity]}  
                            {application.additionalCity !== null ?
                                ` (доп. ${AVAILABLE_CITIES[application.additionalCity]} - ${application.travelDaysToAdditionalCity !== null ? 
                                    application.travelDaysToAdditionalCity : 0} д.)` 
                                : <span className="fw-lighter ms-3" style={{ color: COLORS.warning }}>NO ADD CITY</span>}
                            <span className="mx-3"></span>
                        </p>
                        <p className="fw-light fs-6 mt-3">
                            Приоритет <span className="fw-light ms-1" style={{ color: application.priorityLevel === 1 ? COLORS.danger : COLORS.warning }}>{PRIORITY_LEVELS[application.priorityLevel]}</span>
                            <span className="mx-3"></span>
                            {application.phoneCountryCode + application.phoneNumber}
                        </p>
                    </div>
                    <div className="col">
                        {isBooked ? toDate(application.vfsAccount?.appointment?.dateTime) + ', ' + application.vfsAccount?.appointment?.slotInterval : generatePeriods(application.desiredAppointmentDates.map(el => getMomentObject(el)))
                            .map((el, i) => <p key={i} className="fs-6 fw-light mb-3" style={{ color: COLORS.success }}>
                                {el.start.format("DD.MM.YYYY") + ' – ' + el.end.format("DD.MM.YYYY")}
                        </p>)}
                    </div>
                </div>
                {application.vfsAccount !== null  
                        && <div className="mb-4">
                        <p className="fs-6 fw-bold">
                            Аккаунт VFS
                        </p>
                        <p className="fw-light user-select-all fs-6 mt-2">
                            {application.vfsAccount.assignedEmail}
                        </p>
                        <p className="fw-light user-select-all fs-6">
                            {application.vfsAccount.password}
                        </p>
                    </div>}
                
                {(!isBooked ? application.visaCategoryCodes : [application.vfsAccount.appointment.categoryCode]).map((el, i) => 
                <p key={i} className="fs-6 fw-light mb-2">- {VISA_TYPES.long_term[el] ? VISA_TYPES.long_term[el] 
                    : VISA_TYPES.short_term[el]}</p>)
                    }

                <TextareaAutosize
                    type='text'
                    className='p-2 rounded-3 mt-3'
                    placeholder='Заметки'
                    minHeight='6rem'
                    defaultHeight='1.5rem'
                    defaultValue={application.note !== null ? application.note : ''}
                    style={{ resize: 'none', minWidth: '100px' }}
                    onChange={(e) => handleChangeNotes(e.target.value ? e.target.value : null)}
                />
                
                {application.processingStage === 'CANCELED' ? <></> : isBooked ? <button className="btn primary py-2 mt-5" onClick={handleDownload} style={{ flex: 1 }}>Скачать запись</button> : 
                    <div className="d-flex flex-row mt-5">
                        <button className="btn warning py-2 me-sm-3" onClick={handleEdit} style={{ flex: 1 }}>Изменить</button>
                        {application.processingStage === 'WAITING_FOR_MANAGER_CONFIRMATION' && 
                            <button className="btn primary py-2 me-sm-3" onClick={handleApprove} style={{ flex: 1 }}>Подтвердить заявку</button>}
                        <button className="btn danger py-2 " onClick={handleDelete} style={{ flex: 1 }}>Отменить</button>
                    </div>
                }
            </div>
        </Modal.Body>
      </Modal>
  };