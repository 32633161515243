import { useEffect, useState } from "react";

export const APPLICATION_STAGES = {
    WAITING_FOR_MANAGER_CONFIRMATION: 'Ждёт подтвержения менеджера',
    WAITING_FOR_ORCHESTRATOR: 'Ожидает регистрации',
    VFS_REGISTRATION: 'Регистрация',
    VFS_WAITING_FOR_APPOINTMENT: 'Ждет резервации',
    VFS_APPOINTMENT_BOOKED: 'Записано',
    CANCELED: 'Отменено',
}

export const useDebounceCallback = (func, delay) => {
    const [args, setArgs] = useState(null);
    useEffect(() => {
      if (args === null) return;
      const timeout = setTimeout(() => func(...args), delay);
      return () => clearTimeout(timeout);
    }, [args]); // DO NOT add func and delay to deps
    return (...args) => setArgs(args);
  };