import { SOURCE } from "../utils"

export const createAccessToken = async (data) => {
    const response = await fetch(SOURCE.api_url + 'auth/accessToken',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data)
        }
    )
    return response
}