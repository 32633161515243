import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const initialState = {
	user: {
		loaded: false,
		access_token: Cookies.get('access_token'),
		id: '',
		email: '',
		login: '',
		firstName: '',
		middleName: '',
		lastName: '',
	},
}
export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setAuth: (state, action) => {
			Cookies.set('access_token', action.payload.access_token, { expires: 365 })
			state.user.access_token = action.payload.access_token
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		clearUser: (state, action) => {
			Cookies.remove('access_token')
			state.user = initialState.user
		},
	},
})

export const { setAuth, setUser, clearUser } =
	userSlice.actions

export default userSlice.reducer
