export const AppSelect = ({className = '', label = '', selectClassName = ' p-sm-3 py-2 rounded-3 ',  value, onChange, options, disabled = false, backgroundColor = "#fff"}) => {
    if (typeof options[0] === 'string') {
        options = options.map(el => ({value: el, label: el}))
        console.log(1)
    }
    return <div className={'form-floating ' + className}>
      
        <select className={`form-select fw-lighter ${selectClassName}`} id="floatingSelect" style={{ minWidth: '120px', backgroundColor}}
                disabled={disabled} value={value || ''}
                onChange={e => {onChange(e.target.value === '' ? null : e.target.value)}}>
            {options.map((el, i) => <option key={i} value={el.value || ''}>{el.label}</option>)}
        </select>
        <label htmlFor="floatingSelect" style={{ fontWeight: '200', marginTop: '-6px' }}>{label}</label>
    </div>
}