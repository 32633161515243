import { SOURCE } from "../utils"

export const createPassport = async (token, data) => {
    const response = await fetch(SOURCE.api_url + 'passports',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}

export const patchPassport = async (token, data, id) => {
    const response = await fetch(SOURCE.api_url + 'passports/' + id,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(data)
        }
    )
    return response
}

export const recognizePassport = async (token, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await fetch(SOURCE.api_url + 'passports/recognize',
        {
            method: 'POST',
            headers: {
                "Authorization": token
            },
            body: formData
        }
    )
    return response
}