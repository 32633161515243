import { SOURCE } from "../utils"

export const getMe = async (token, ) => {
    const response = await fetch(SOURCE.api_url + 'managers/me',
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token
            },
        }
    )
    return response
}