import { createRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showErrorMsg } from "../../../../store/slices/alertSlice"
import { Loader } from "../../../../ui/Loader"
import { bulkCreateApplications, importData } from "../../../../api/application"
import { clearApplication, getAllApplicationsAsync, setApplication, setSearchParams, setStep } from "../../../../store/slices/applications"
import { CITIZENSHIPS, CREATE_APPLICATION_STEPS, VISA_TYPES } from "../utils"
import { COLORS, prettyDateToISO } from "../../../../utils"
import { AppSelectCheckBox } from "../../../../ui/AppSelectCheckbox"

export const UploadApplicationsList = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userSlice.user)
  const [state, setState] = useState({
    isLoading: false,
    fileUpload: null,

  })
  const handleUploadFile = async () => {
    try {
      const response = await importData(user.access_token, state.fileUpload)
      if (!response.ok) {
        if (response.status === 409) dispatch(showErrorMsg('Сервис распознавания не отвечает. Попробуйте позже'))
        else if (response.status === 413) dispatch(showErrorMsg('Файл слишком большой'))
        else if (response.status === 415) dispatch(showErrorMsg('Invalid file mime type'))
        else dispatch(showErrorMsg('Ошибка на сервере'))
        return setState({ ...state, isLoading: false })
      }
      const res = await response.json()
      setState({ ...state, isLoading: false })
      dispatch(setApplication({step: CREATE_APPLICATION_STEPS.FILE_PREVIEW, bulkApplicationData: res}))
    } catch(e) {
      dispatch(showErrorMsg('Ошибка на сервере'))
    }
  }
  const fileRef = createRef()
  return <div className="d-flex flex-column m-auto justify-content-center w-100" style={{ maxWidth: '400px', height: '400px' }}>
    <p className="fs-4 fw-light text-center mb-4">Откуда брать данные</p>
    <div className="rounded-3 px-3 py-3" onClick={() =>!state.isLoading && fileRef.current.click()} 
      style={{ border: '1px solid #ced4da', cursor: 'pointer' }}>
        <p className="fw-lighter" style={{ color: state.fileUpload ? 'black' : COLORS.dark_gray }}>{state.fileUpload ? state.fileUpload.name : 'Документ'}</p>
    </div>
    <button className="btn primary w-100 py-3 mt-4" disabled={state.isLoading} onClick={handleUploadFile}>{state.isLoading ? <Loader size="small" color='white'/> :  'Продолжить'}</button>
    <div>
      <input 
        ref={fileRef}
        type="file" accept=".xlsx"
        className='d-none'
        onChange={(e) => {
          let f = e.target.files[0];
          if (
            ['xlsx'].indexOf(
              f.name.split('.').at(-1)
            ) < 0
          ) {
            dispatch(showErrorMsg('Недопустимый формат файла'));
          } else if (f.size < 1500 * 10 ** 6) {
            setState((p) => ({ ...p, fileUpload: f }));
          } else
            dispatch(
              showErrorMsg('Размер файла не должен превышать 3 ГБ')
            );
          e.target.value = null;
        }}  />
    </div>
  </div>
}

export const FilePreview = ({ onCloseModal }) => {
  const user = useSelector(state => state.userSlice.user)
  const application = useSelector(state => state.applicationsSlice.application)
  const dispatch = useDispatch()

  const [state, setState] = useState({
    visaCategoryCodes: [],
  })

  let suggestions = []
  if (state.visaCategoryCodes.length === 0)
    suggestions = [...Object.keys(VISA_TYPES.short_term), ...Object.keys(VISA_TYPES.long_term)]
  else if (Object.keys(VISA_TYPES.long_term).filter(key => state.visaCategoryCodes.indexOf(key) >= 0).length > 0)
    suggestions = Object.keys(VISA_TYPES.long_term).filter(key => state.visaCategoryCodes.indexOf(key) < 0)
  else suggestions = Object.keys(VISA_TYPES.short_term).filter(key => state.visaCategoryCodes.indexOf(key) < 0)

  const handleVisaCodes = (c) => {
    if (state.visaCategoryCodes.indexOf(c) >= 0)
      return setState({ ...state, visaCategoryCodes: state.visaCategoryCodes.filter(el => el !== c) })
    return setState({ ...state, visaCategoryCodes:[ ...state.visaCategoryCodes, c] })
  }
  

  const handleContinue = async () => {
    dispatch(setApplication({ isLoading: true }))
    const response = await bulkCreateApplications(user.access_token, 
      application.bulkApplicationData.map(el => {
        const result = ({ ...el, 
            desiredAppointmentDates:  el.desiredAppointmentDates.map(d => prettyDateToISO(d.split('-').reverse().join('.'))),
            visaCategoryCodes: state.visaCategoryCodes,
            passport: {
              ...el.passport,
              citizenship: CITIZENSHIPS[el.passport.citizenship] ? el.passport.citizenship : 'RUS'
            }
          })

        delete result.processingStage
        return result
      }))
    if (!response.ok) return dispatch(showErrorMsg('Ошибка при создании заявок'))
    onCloseModal()
    dispatch(clearApplication())
    dispatch(setSearchParams({ currentPage: null }))
  }
  return <div className="d-flex flex-column">
    <p className="fs-5">Тип виз для указанного списка</p>
    <AppSelectCheckBox className="mb-sm-3 mb-2 mt-3" chosenList={state.visaCategoryCodes} suggestions={suggestions} onChoose={handleVisaCodes}
      label='Вид визы' />
    <p className="fs-6 fw-light" style={{ color: COLORS.dark_gray }}>
      Будет создано { application.bulkApplicationData.length } заявок.
      <br />
      После завершения процесса импорта необходимо <span className="fw-bold" style={{ color: 'black' }}>подтвердить</span> правильность данных каждой заявки.
    </p>
    <button className='btn primary py-3 w-100 mx-auto mt-4' onClick={handleContinue} disabled={application.isLoading}
      style={{ maxWidth: '500px' }}>{application.isLoading ? <Loader size="small" color="white" /> : 'Создать'}</button>
  </div>
}