import { Navigate, Route, Routes } from "react-router-dom"
import { Main } from "./main/Main"
import { useSelector } from "react-redux"

export const Account = () => {
    const user = useSelector(state => state.userSlice.user)

    if (!user.access_token) return <Navigate to='/auth' />

    return <Routes>
        <Route path="/" element={<Main />} />
    </Routes>
}