
import InputMask from 'react-input-mask';
import { ReactComponent as DownloadSvg } from '../../../../icons/download.svg'
import { AppInput } from '../../../../ui/AppInput';
import { COLORS, Validation, isCorrectDate, prettyDateToISO } from "../../../../utils"
import { createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMsg } from '../../../../store/slices/alertSlice';
import { AppSelect } from '../../../../ui/AppSelect';
import { CITIZENSHIPS, CREATE_APPLICATION_STEPS, GENDERS } from '../utils';
import { setPassport, setStep } from '../../../../store/slices/applications';
import { validationPassportSchema } from '../../../../yup';
import { recognizePassport } from '../../../../api/passport';
import { Loader } from '../../../../ui/Loader';




export const CreateSinglePassport = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
      recognizedFile: null,
      isLoading: false
    })

    const user = useSelector(state => state.userSlice.user)
    const fileRef = createRef()

    const handleRecognizeFile = async (file) => {
      try {
        setState({ ...state, isLoading: true })
        const response = await recognizePassport(user.access_token, file)
        if (!response.ok){
          if (response.status === 409) dispatch(showErrorMsg('Сервис распознавания не отвечает. Попробуйте позже'))
          else if (response.status === 413) dispatch(showErrorMsg('Файл слишком большой'))
          else if (response.status === 415) dispatch(showErrorMsg('Invalid file mime type'))
          else dispatch(showErrorMsg('Ошибка на сервере'))
          return setState({ ...state, isLoading: false })
        }
        const res = await response.json()
        setState({ ...state, isLoading: false, recognizedFile: {...res, 
          birthDate: res.birthDate.split('-').reverse().join('.'),
          expirationDate: res.expirationDate.split('-').reverse().join('.')} })

      } catch(e) {
        dispatch(showErrorMsg('Ошибка при загрузке файла'))
        setState({ ...state, isLoading: false })
      } 
    }


    return state.isLoading ? <Loader /> : <div className="row">
      <div className="col-sm-5 pe-sm-5">
        <input 
          ref={fileRef}
          type="file" accept=".jpg"
          className='d-none'
          onChange={(e) => {
            let f = e.target.files[0];
            if (
              ['jpg'].indexOf(
                f.name.split('.').at(-1)
              ) < 0
            ) {
              dispatch(showErrorMsg('Допустимый формат файла - .jpg'));
            } else if (f.size < 1500 * 10 ** 6) {
              handleRecognizeFile(f)
            } else
              dispatch(
                showErrorMsg('Размер файла не должен превышать 3 ГБ')
              );
            e.target.value = null;
          }}  />
        <div className="d-flex flex-column h-100 w-100 rounded-5" onClick={() => fileRef.current.click()}
          style={{ border: '1px dashed ' + COLORS.dark_gray, cursor: 'pointer' }}>
            <div className="d-flex flex-column m-auto" style={{ maxWidth: '350px' }}>
              <DownloadSvg className="mx-auto mb-3" />
              <p className="fs-6 fw-lighter text-center" style={{ color: COLORS.dark_gray }}>
                Перенесите или загрузите из проводника файл с паспортом заявителя (.jpg)
              </p>
            </div>
        </div>
      </div>
      <div className="col d-flex flex-column">
        <PassportForm recognizedData={state.recognizedFile} />
      </div>
    </div>
  }
  
  



  
const PassportForm = ({ recognizedData }) => {
  const application = useSelector(state => state.applicationsSlice.application)
  const [data, setData] = useState(recognizedData === null ? application.passport : 
    { 
      ...recognizedData,
      citizenship: CITIZENSHIPS[application.passport.citizenship] ? application.passport.citizenship : 'RUS' })
  const dispatch = useDispatch()

  const saveHandler = async () => {
    const result = {...data, expirationDate: prettyDateToISO(data.expirationDate), birthDate: prettyDateToISO(data.birthDate)}
    const valid = await validationPassportSchema.isValid(result)
    if (!valid || !(isCorrectDate(result.expirationDate) && isCorrectDate(result.birthDate)))
      return dispatch(showErrorMsg('Поля заполнены некорректно'))
    dispatch(setPassport(data))
    dispatch(setStep(CREATE_APPLICATION_STEPS.APPLICATION_SINGLE))
  }

  return <>
  <p className="fs-5 mb-3">Данные заявителя</p>
    <div className="row row-cols-1 row-cols-md-2 gy-sm-3 gy-2">
      <div className="col">
        <AppInput label='Фамилия' value={data.lastName} onChange={v => setData({ ...data, lastName: v })} />
      </div>
      <div className="col">
        <AppInput label='Имя' value={data.firstName} onChange={v => setData({ ...data, firstName: v })} />
      </div>
      <div className="col">
        <div className="form-floating">
          <InputMask 
            mask='99.99.9999'
            id="floatingInput" 
            placeholder='Дата рождения' 
            className={"form-control rounded-3 "} 
            value={data.birthDate} 
            onChange={(e,v) => setData({ ...data, birthDate: v ? v : e.target.value })}
            onClick={e => e.preventDefault()}/>
          <label htmlFor="floatingInput" className='fs-6' style={{ fontWeight: '200', marginTop: '-2px' }}>Дата рождения</label>
        </div>
      </div>
      <div className="col">
        <AppSelect label='Пол' value={data.gender} options={[{ value: null, label: 'Не выбрано' }, ...Object.keys(GENDERS).map(el => ({ value: el, label: GENDERS[el] }))]}
        onChange={v => setData({ ...data, gender: v })} />
      </div>
      <div className="col">
        <AppSelect label='Гражданство' value={data.citizenship} options={[{ value: null, label: 'Не выбрано' }, ...Object.keys(CITIZENSHIPS).map(el => ({ value: el, label: CITIZENSHIPS[el] }))]}
          onChange={v => setData({ ...data, citizenship: v })} />
      </div>
      <div className="col">
        <AppInput label='Номер паспорта' value={data.number} onChange={v => setData({ ...data, number: v })} />
      </div>
      <div className="col">
        <div className="form-floating">
          <InputMask 
            mask='99.99.9999'
            id="floatingInput" 
            placeholder='Истекает' 
            className={"form-control rounded-3 "} 
            value={data.expirationDate} 
            onChange={(e,v) => setData({ ...data, expirationDate: v ? v : e.target.value })}
            onClick={e => e.preventDefault()}/>
          <label htmlFor="floatingInput" className='fs-6' style={{ fontWeight: '200', marginTop: '-2px' }}>Истекает</label>
        </div>
      </div>
      <div className="col d-flex ">
        <button className="btn primary px-4 py-2 ms-auto mt-3" onClick={saveHandler}>Продолжить</button>
      </div>
    </div>
  </>
}